import React from 'react';
import Layout from '../components/Layout';
import {Container, Grid, makeStyles} from "@material-ui/core";
import {graphql, useStaticQuery} from "gatsby"


const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
}));


export default function App() {

    const classes = useStyles();

    const data = useStaticQuery(graphql`
    {
      heroImage:file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "lobby-1.jpg"}) {
        img:childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

    return (
        <Layout heroHeadline1="über uns"
                heroImage={data.heroImage.img.fluid}
        >
            <Container className={classes.cardGrid}>
                <Grid
                    spacing={10}// Add it here :)
                    container
                >
                    <Grid item xs={12}>
                        <p>
                            Sie sind bei uns gut aufgehoben, wenn Sie anwaltliche Beratung und Unterstützung in den
                            Bereichen Baurecht, Verkehrsrecht und Verwaltungsrecht suchen. Unsere Beratungskompetenz
                            liegt auch in der langjährigen Beratung von Kommunen, Zweckverbänden und
                            Kommunalgesellschaften.
                        </p>
                        <p>
                            Wir sitzen zentral in München auf der Schwanthalerhöhe und sind für Sie öffentlich und mit
                            dem eigenen PKW sehr gut erreichbar.
                        </p>
                        <p>
                            Es macht uns jeden Tag große Freude gemeinsam mit unseren Mandanten Probleme zu lösen oder
                            sogar zu vermeiden.
                        </p>
                        <p>
                            Gerne sind wir auch für Sie da.
                        </p>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}
